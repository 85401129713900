import React from 'react'
import Layout from '../components/Layout'
import MenuList from '../components/MenuList'

const IndexPage = () => (
  <Layout>
    <MenuList />
  </Layout>
)

export default IndexPage
