import React from 'react'
import useMenuListQuery from '../hooks/useMenuListQuery'
import { getMenuObject, deleteSpaces } from '../utils/helpers'
import useLanguageState from '../hooks/useLanguageState'

const MenuList = () => {
  const languageState = useLanguageState()
  const menu = getMenuObject(useMenuListQuery())

  return (
    <div className="menu-list container">
      <ul className="menu-list__list">
        {menu.map(category => (
          <li
            className="menu-list__anchor menu-list__list-item menu-list__list-item--category"
            key={Math.random()}
            id={deleteSpaces(category.categoryName[0])}
          >
            <h2 className="menu-list__category-heading">
              {category.categoryName[languageState.currentLanguageIndex]}
            </h2>

            <ul className="menu-list__list menu-list__list--sub-category">
              {category.subCategories.map(subCategory => (
                <li
                  className="menu-list__anchor menu-list__list-item menu-list__list-item--sub-category"
                  key={Math.random()}
                  id={deleteSpaces(
                    `${category.categoryName[0]}-${subCategory.subCategoryName[0]}`
                  )}
                >
                  <h3
                    className={`menu-list__category-sub-heading menu-list__category-sub-heading--${
                      category.categoryName[
                        languageState.currentLanguageIndex
                      ] ===
                      subCategory.subCategoryName[
                        languageState.currentLanguageIndex
                      ]
                        ? 'hidden'
                        : null
                    }`}
                  >
                    {
                      subCategory.subCategoryName[
                        languageState.currentLanguageIndex
                      ]
                    }
                  </h3>

                  <ul className="menu-list__list menu-list__list--menu-items">
                    {subCategory.menuItems.map(item => (
                      <li className="menu-list__menu-item" key={Math.random()}>
                        <div className="menu-list__text-field">
                          <div className="menu-list__heading-wrapper">
                            <h4 className="menu-list__menu-item-heading">
                              {item.heading[languageState.currentLanguageIndex]}
                            </h4>
                          </div>
                          <p className="menu-list__menu-item-text">
                            {item.text[languageState.currentLanguageIndex]}
                          </p>
                        </div>

                        <span className="menu-list__menu-item-size">
                          {item.size}
                        </span>
                        <span className="menu-list__menu-item-price">
                          {`${item.price} `}
                          &#8372;
                        </span>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default MenuList
